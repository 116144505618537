import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { Nav, Navbar, Container } from "react-bootstrap"

import logo from "../images/marin-county-remodel-logo.svg"

const Header = ({siteTitle}) => (
  <header>
    <Container>
      <Navbar expand="md">
        <Link to="/"><img src={logo} alt={siteTitle} width="350" height="93" className="logo" /></Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">

          <Nav className="navbar-nav text-center ms-auto">
            <ul className="navbar-nav  text-center">
              <li><Link to="/about/" partiallyActive={true} className="nav-link" activeClassName="active">About Us</Link></li>
              <li><Link to="/adu/" partiallyActive={true} className="nav-link" activeClassName="active">ADUs</Link></li>
              <li><Link to="/additions/" partiallyActive={true} className="nav-link" activeClassName="active">Additons</Link></li>
              <li><Link to="/process/" partiallyActive={true} className="nav-link" activeClassName="active">Process</Link></li>
              <li><Link to="/faq/" partiallyActive={true} className="nav-link" activeClassName="active">FAQ</Link></li>
              <li><Link to="/testimonials/" partiallyActive={true} className="nav-link" activeClassName="active">Testimonials</Link></li>
              <li><Link to="/contact/" partiallyActive={true} className="nav-link" activeClassName="active">Contact</Link></li>
            </ul>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
