/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

// css files
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/custom.css'

// Font Awesome
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

// React Bootstrap
import { Container } from "react-bootstrap"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main>{children}</main>
        <footer className="py-5">

          <Container>
            {/* <div className="d-flex justify-content-center">
              <a href="https://twitter.com" className="mx-3">
                <FontAwesomeIcon icon={faTwitter} size="lg" />
              </a>
              <a href="https://instagram.com" className="mx-3">
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </a>
            </div> */}
            <p className="text-center my-0">
            <small>© {new Date().getFullYear()}, Jim Kautz Architecture. All rights reserved. 4130 Paradise Dr., Tiburon, CA 94920</small>
            </p>
            <p className="text-center"><small>Site designed by <a href="https://nautilusdesigns.com">Nautilus Designs</a></small></p>
          </Container>
          
        </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
